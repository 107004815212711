<script>
    import { _, locale } from 'svelte-i18n'
    import tippy from 'sveltejs-tippy'
    import * as sort from '$utils/sort'
    import { flip } from 'svelte/animate'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { dedupe, getDisplay } from '$utils/common'
    import { trimEthAddress, getWallet } from '$utils/ethereum'
    import { updateAccounts } from '$utils/profile'
    import { logPlausibleEvent } from '$utils/plausible'
    import {
        getProfile,
        postLinkEth,
        postLinkEthChallenge,
    } from '$utils/api.js'
    import { FLIP_ANIM_MS, SLIDE_ANIM_MS } from '$src/constants.js'
    import logins from '$svr/providers/logins.json'
    import WarningIcon from '$lib/icon/WarningIcon.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'
    import HelpButton from '$lib/button/HelpButton.svelte'
    import CloseIcon from '$lib/icon/CloseIcon.svelte'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import VerifyPhone from '$lib/VerifyPhone.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import Dropdown from '$lib/Dropdown.svelte'
    import PhoneIcon from '$lib/icon/PhoneIcon.svelte'
    import LoginProviderGroup from '$lib/LoginProviderGroup.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import Modal from '$lib/modal/Modal.svelte'
    import RemoveButton from '$lib/button/RemoveButton.svelte'
    import RemoveIconButton from '$lib/button/RemoveIconButton.svelte'
    import PromoteIcon from '$lib/icon/PromoteIcon.svelte'
    import DemoteIcon from '$lib/icon/DemoteIcon.svelte'

    let {
        editMode = false,
        verifyProvider = $bindable(),
        verifyProviderAuthority = $bindable(),
        dropdownStates = $bindable({}),
        confirmProviderDelete = $bindable(),
        removeProviderAjax = false,
        removeUnverifiedProviderAjax = false,
        handleDropdown = () => {},
        continueWithProvider = () => {},
        removeProvider = () => {},
        removeUnverifiedProvider = () => {},
        verifyEmailSuccess = () => {},
        verifyPhoneSuccess = () => {},
        removeRecovery = async () => {},
        makePreferred = async () => {},
        makeRecovery = async () => {},
    } = $props()

    let emailOTPState = $state(false)
    let phoneOTPState = $state(false)
    let ethereumProgressModal = $state(null) //Will hold extension values
    let ethereumProgressNotifs = $state([])

    async function verifyContactAuthoritySuccess() {
        const account = global.data.profile.accounts.find(
            (i) => i.id === verifyProviderAuthority.id,
        )
        if (verifyProviderAuthority.action.preferred) {
            await makePreferred(account)
        } else if (verifyProviderAuthority.action.recovery) {
            await makeRecovery(account)
        } else if (verifyProviderAuthority.action.no_recovery) {
            await removeRecovery(account)
        }
        verifyProviderAuthority = null
    }

    async function verifyEthereumForAuthority() {
        const account = global.data.profile.accounts.find(
            (i) => i.id === verifyProviderAuthority.id,
        )
        const action = verifyProviderAuthority.action
        verifyProviderAuthority = null //hide verify modal to show ethereum signing state modal
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        const verify = true
        await continueEthExtensionSigning(address, verify)
        if (action.preferred) {
            await makePreferred(account)
        } else if (action.recovery) {
            await makeRecovery(account)
        } else if (action.no_recovery) {
            await removeRecovery(account)
        }
    }

    async function continueWithEthereumExtension() {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        continueEthExtensionSigning(address)
    }

    async function continueEthExtensionSigning(address, verify) {
        let challenge, signature

        const res = await postLinkEth(address)
        if (verify) {
            logPlausibleEvent({
                u: `/start/verify/ethereum/extension/${getWallet().slug}`,
                n: 'action',
            })
        } else {
            logPlausibleEvent({
                u: `/start/link/ethereum/extension/${getWallet().slug}`,
                n: 'action',
            })
        }
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Linking wallet'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_(`You've rejected the sign request`),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
        }

        await postLinkEthChallenge(body)
        if (verify) {
            logPlausibleEvent({
                u: `/verify/ethereum/extension/${getWallet().slug}`,
                n: 'action',
            })
        } else {
            logPlausibleEvent({
                u: `/link/ethereum/extension/${getWallet().slug}`,
                n: 'action',
            })
            notification.show(
                $_('{provider} {label} has been added', {
                    values: {
                        provider: body.name,
                        label: trimEthAddress(address),
                    },
                }),
                'success',
            )
        }
        global.data = await getProfile()
        global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
        dropdownStates.provider = false
        ethereumProgressModal = false
        ethereumProgressNotifs = []
    }

    async function verifyProviderForAuthority() {
        global.spinner = true
        sessionStorage.setItem(
            'authority',
            JSON.stringify(verifyProviderAuthority.action),
        )
        const account = global.data.profile.accounts.find(
            (i) => i.id === verifyProviderAuthority.id,
        )
        await logPlausibleEvent({
            u: `/start/verify/${account.slug}`,
            n: 'action',
        })
        window.location.href = verifyProviderAuthority.redirect_uri
    }

    function closeModal() {
        ethereumProgressModal = verifyProvider = verifyProviderAuthority = null
        ethereumProgressNotifs = []
    }

    function verifyContactError() {
        verifyProvider = null
    }

    let recoveriesLength = $derived(
        global.data?.profile?.accounts?.filter(
            (i) => !i.preferred && i.recovery && !i.communal,
        )?.length,
    )
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

<table id="personal-providers" class="mt-10 w-full table-fixed">
    <thead>
        <tr class="relative">
            <th class="w-1/2 text-left text-lg font-medium md:w-3/5">
                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <span>{$_('Personal Providers')}</span>
                        <HelpButton
                            ariaLabel="Help"
                            content={$_(
                                'Providers you can use to log in or recover your Hellō Wallet',
                            )}
                            placement="top"
                        />
                    </div>
                </div>
            </th>
        </tr>
    </thead>
</table>

<div class="mt-2 w-full">
    {#if verifyProviderAuthority}
        <FullPageModal dataTest="verify-preferred-modal" close={closeModal}>
            <button
                aria-label="Close"
                onclick={closeModal}
                class="group absolute right-4 top-6"
            >
                <CloseIcon />
            </button>
            {#if verifyProviderAuthority.slug === 'email'}
                <h3 class="mb-4 text-center font-medium">
                    {$_('Verify your email')}
                </h3>
                <VerifyEmail
                    email={verifyProviderAuthority.user_name}
                    disabled
                    verify
                    success={verifyContactAuthoritySuccess}
                />
            {:else if verifyProviderAuthority.slug === 'phone'}
                <h3 class="mb-4 text-center font-medium">
                    {$_('Verify your phone')}
                </h3>
                <VerifyPhone
                    phone={verifyProviderAuthority.user_name}
                    disabled
                    verify
                    success={verifyContactAuthoritySuccess}
                />
            {:else if verifyProviderAuthority.slug === 'ethereum'}
                <h3 class="mb-4 text-center font-medium">
                    {$_('Verify your account')}
                </h3>
                <div
                    class="btn-border hover-none relative h-auto space-y-2 rounded-md p-3"
                >
                    <span class="mb-3 block text-left"
                        >{verifyProviderAuthority.wallet?.name || 'Ethereum'}
                        {#if verifyProviderAuthority.wallet?.display}
                            <span
                                use:tippy={{
                                    content: verifyProviderAuthority.login_hint,
                                    placement: 'top',
                                }}
                            >
                                ({verifyProviderAuthority.wallet?.display})
                            </span>
                        {/if}
                    </span>
                    {#if verifyProviderAuthority.slug === 'ethereum' && window.ethereum}
                        <LoginProvider
                            autofocus={true}
                            ethereum={verifyEthereumForAuthority}
                            hideusername
                            provider={verifyProviderAuthority}
                            prefix="Verify with"
                        />
                    {/if}
                </div>
            {:else}
                <h3 class="mb-4 text-center font-medium">
                    {$_('Verify your account')}
                </h3>
                <LoginProvider
                    onclick={verifyProviderForAuthority}
                    provider={verifyProviderAuthority}
                    prefix="Verify with"
                    autofocus={true}
                />
            {/if}
        </FullPageModal>
    {/if}

    {#if verifyProvider}
        <FullPageModal dataTest="verify-modal" close={closeModal}>
            <button
                aria-label="Close"
                onclick={closeModal}
                class="group absolute right-4 top-6 z-[60]"
            >
                <CloseIcon />
            </button>
            <div class="mb-4 flex items-center justify-center opacity-80">
                {#if global.data.profile.unverified_emails.includes(verifyProvider)}
                    <MailIcon />
                {:else if global.data.profile.unverified_phones.includes(verifyProvider)}
                    <PhoneIcon />
                {/if}
                <h3 class="ml-2 text-center font-medium">
                    {global.data.profile.unverified_emails.includes(
                        verifyProvider,
                    )
                        ? $_('Verify your email')
                        : $_('Verify your email')}
                </h3>
            </div>
            {#if global.data.profile.unverified_emails.includes(verifyProvider)}
                <VerifyEmail
                    email={verifyProvider}
                    disabled
                    verify
                    success={verifyEmailSuccess}
                    error={verifyContactError}
                />
            {:else if global.data.profile.unverified_phones.includes(verifyProvider)}
                <VerifyPhone
                    phone={verifyProvider}
                    disabled
                    verify
                    success={verifyPhoneSuccess}
                    error={verifyContactError}
                />
            {/if}
        </FullPageModal>
    {/if}

    <div class="space-y-2">
        <!-- Checking doneWizardAt here because we don't want to sort accounts in wizard flow -->
        {#if global.data.profile.accounts.length && global.data.actions?.doneWizardAt}
            {#each dedupe(global.data.profile.accounts, 'id')
                .filter((i) => !i.managed && !i.linkedAccount)
                .sort(sort.accounts) as account, index (account.id)}
                {@const recoveryCount = global.data.profile.accounts.filter(
                    (i) => !i.preferred && i.recovery,
                ).length}
                <div
                    data-test="profile-provider-{index}"
                    class="relative flex items-center justify-between"
                    animate:flip={{ duration: FLIP_ANIM_MS }}
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <!-- (account.verifiedAt check) Delete verified vs unverified -->
                    {#if confirmProviderDelete && (account.verifiedAt ? confirmProviderDelete === account.id : confirmProviderDelete === account.user_name)}
                        <Modal
                            position="left"
                            css="top-10 md:min-w-[22rem]"
                            close={() => (confirmProviderDelete = null)}
                        >
                            <div
                                class="flex flex-col items-center justify-between md:flex-row"
                            >
                                <span>{$_('Confirm Deletion')}</span>
                                <div
                                    class="mt-4 flex items-center gap-x-4 md:mt-0 md:space-x-2"
                                >
                                    <button
                                        onclick={() =>
                                            (confirmProviderDelete = null)}
                                        class="btn-border h-9 w-20 px-0 text-sm"
                                        >{$_('Cancel')}</button
                                    >
                                    <RemoveButton
                                        dataTest="remove-provider-{index}-btn-confirm"
                                        onclick={() => {
                                            account.verifiedAt
                                                ? removeProvider(account.id)
                                                : removeUnverifiedProvider(
                                                      account.user_name,
                                                  )
                                        }}
                                        isLoading={account.verifiedAt
                                            ? removeProviderAjax === account.id
                                            : removeUnverifiedProviderAjax ===
                                              account.user_name}
                                        value="Delete"
                                    />
                                </div>
                            </div>
                        </Modal>
                    {/if}

                    <div class="flex w-full items-center truncate">
                        <div
                            class="mt-1 inline-flex w-6 flex-shrink-0 items-center justify-start py-3 md:mt-0 md:py-0"
                        >
                            {#if editMode && !account.preferred && !(global.data.profile.accounts.filter((i) => i.recovery && !i.preferred).length <= 3 && account.recovery)}
                                <RemoveIconButton
                                    dataTest="remove-provider-{index}-btn"
                                    onclick={() =>
                                        account.verifiedAt
                                            ? (confirmProviderDelete =
                                                  account.id)
                                            : (confirmProviderDelete =
                                                  account.user_name)}
                                />
                            {/if}
                        </div>
                        <div
                            class="bg-charcoal relative flex w-full items-center truncate rounded-md bg-opacity-10 px-4 py-1 py-3 dark:bg-opacity-100"
                        >
                            <span
                                class="-ml-2 flex h-[54px] w-[54px] flex-shrink-0 items-center justify-center md:h-auto md:w-9"
                            >
                                {#if account.slug === 'ethereum' && account.wallet}
                                    <img
                                        src={account.wallet.icon}
                                        alt={account.wallet.name}
                                        class="w-4.5 max-h-[18px]"
                                    />
                                {:else if account.slug === 'email'}
                                    <MailIcon />
                                {:else if account.slug === 'phone'}
                                    <PhoneIcon />
                                {:else if account.mastodonServer}
                                    <!-- TBD Broken -- Defaulting to default mastodon logo -->
                                    <!-- <img
										src="https://{account.mastodonServer}/favicon.ico"
										alt="Mastodon"
										class="w-4.5 max-h-[18px]"
									/> -->
                                    <img
                                        src="https://cdn.hello.coop/images/mastodon.svg"
                                        alt="Mastodon"
                                        class="w-4.5 max-h-[18px]"
                                    />
                                {:else}
                                    <img
                                        src="https://cdn.hello.coop/images/{account.slug}.svg"
                                        alt={account.slug}
                                        class="md:w-4.5 max-h-[36px] w-9 object-contain md:max-h-[18px] {[
                                            'apple',
                                            'twitter',
                                            'github',
                                        ].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
                                            ? 'dark:invert-20 invert'
                                            : ''}"
                                    />
                                {/if}
                            </span>
                            <div
                                class="ml-2 flex w-full flex-col truncate px-0.5 md:flex-row"
                            >
                                <span
                                    data-test="profile-provider-{index}-label"
                                    class="flex-1 truncate font-medium"
                                    class:font-bold={account.preferred}
                                    class:text-red-500={!account.verifiedAt &&
                                        ['email', 'phone'].includes(
                                            account.slug,
                                        )}
                                >
                                    {#if account.slug === 'phone'}
                                        {window.intlTelInputUtils.formatNumber(
                                            account.user_name,
                                            null,
                                            window.intlTelInputUtils
                                                .numberFormat.INTERNATIONAL,
                                        )}
                                    {:else if account.slug === 'email'}
                                        {account.user_name}
                                    {:else if account.slug === 'ethereum'}
                                        <span
                                            use:tippy={{
                                                content: account.login_hint,
                                                placement: 'top',
                                            }}
                                        >
                                            {#if account.wallet}
                                                {account.wallet.name} ({account.user_name})
                                            {:else}
                                                Ethereum ({account.user_name})
                                            {/if}
                                        </span>
                                    {:else}
                                        {getDisplay(account.slug) +
                                            ' ' +
                                            (account.mastodonServer &&
                                            account.user_name
                                                ? `(@${account.user_name}@${account.mastodonServer})`
                                                : account.user_name
                                                  ? `(${account.user_name})`
                                                  : '')}
                                    {/if}
                                </span>
                                <div
                                    class="flex items-center justify-start gap-x-2 py-0.5 {$locale &&
                                    $locale.startsWith('ar')
                                        ? 'mr-0 md:mr-3'
                                        : 'ml-0 md:ml-3'} md:mt-0"
                                >
                                    {#if !account.preferred && account.recovery && account.verifiedAt && !account.linkedAccount}
                                        {#if recoveryCount <= 3}
                                            <button
                                                use:tippy={{
                                                    content: $_(
                                                        'You cannot demote your only {count} recovery provider(s)',
                                                        {
                                                            values: {
                                                                count: recoveryCount,
                                                            },
                                                        },
                                                    ),
                                                    placement: 'top',
                                                }}
                                                data-test="demote-{index}-btn"
                                                class="btn-border h-4.5 w-4.5 cursor-not-allowed opacity-50"
                                            >
                                                <DemoteIcon />
                                            </button>
                                        {:else}
                                            <button
                                                data-test="demote-{index}-btn"
                                                onclick={() =>
                                                    removeRecovery(account)}
                                                class="btn-border h-4.5 w-4.5"
                                            >
                                                <DemoteIcon />
                                            </button>
                                        {/if}
                                    {/if}

                                    {#if account.preferred}
                                        <span
                                            class="inline-block truncate text-sm font-bold uppercase
                                        {$locale && $locale.startsWith('ar')
                                                ? 'text-right md:text-left'
                                                : 'text-left md:text-right'} flex-shrink-0"
                                        >
                                            {$_('Preferred')}
                                        </span>
                                    {:else if account.communal}
                                        <span
                                            class="inline-block truncate text-sm font-bold uppercase
                                        {$locale && $locale.startsWith('ar')
                                                ? 'text-right md:text-left'
                                                : 'text-left md:text-right'} flex-shrink-0"
                                        >
                                            {$_('Communal')}
                                        </span>
                                    {:else if account.recovery}
                                        <span
                                            class="inline-block truncate text-sm font-bold uppercase
                                        {$locale && $locale.startsWith('ar')
                                                ? 'text-right md:text-left'
                                                : 'text-left md:text-right'} flex-shrink-0"
                                        >
                                            {$_('Recovery')}
                                        </span>
                                    {/if}

                                    {#if !account.verifiedAt && ['email', 'phone'].includes(account.slug)}
                                        <button
                                            data-test="verify-{index}-btn"
                                            class="btn-background h-5 w-20 truncate px-1 text-xs font-bold uppercase"
                                            onclick={() =>
                                                (verifyProvider =
                                                    account.user_name)}
                                        >
                                            <span class="truncate"
                                                >{$_('Verify')}</span
                                            >
                                        </button>
                                    {:else if account.preferred}
                                        <!-- <span class="text-xs font-semibold" /> -->
                                    {:else if account.communal}
                                        <!-- <span /> -->
                                        <!-- Phone can only be added as recovery and not preferred -->
                                    {:else if account.recovery && !['phone'].includes(account.slug)}
                                        <!-- <PutAuthorityButton
                                            dataTest="promote-{index}-btn"
                                            onclick={() => changePreferred(account)}
                                            value="Make Preferred"
                                            isLoading={changePreferredAjax === account.id}
                                        /> -->
                                    {/if}

                                    {#if account.linkedAccount}
                                        {logins.find(
                                            (i) =>
                                                i.slug ===
                                                account.linkedAccount,
                                        ).display}
                                    {/if}

                                    {#if account.preferred}
                                        <button
                                            use:tippy={{
                                                content: $_(
                                                    'You log in with your preferred provider. If you lose access to your preferred provider, you can log in with two recovery providers',
                                                ),
                                                placement: 'top',
                                            }}
                                            class="btn-border h-4.5 w-4.5 relative flex items-center justify-center overflow-hidden text-xs font-medium"
                                        >
                                            ?
                                        </button>
                                    {:else if account.linkedAccount}
                                        <img
                                            src="https://cdn.hello.coop/images/{account.linkedAccount}.svg"
                                            alt={account.linkedAccount}
                                            class="w-4.5 max-h-[18px] {[
                                                'apple',
                                                'twitter',
                                            ].includes(account.linkedAccount) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
                                                ? 'dark:invert-20 invert'
                                                : ''}"
                                        />
                                    {:else if ['instagram'].includes(account.slug)}
                                        <button
                                            use:tippy={{
                                                content: $_(
                                                    'Instagram cannot be upgraded to a recovery provider',
                                                ),
                                                placement: 'top',
                                            }}
                                            class="btn-border h-4.5 w-4.5 relative flex cursor-not-allowed items-center justify-center overflow-hidden text-xs font-medium opacity-50"
                                        >
                                            ?
                                        </button>
                                    {:else if account.slug === 'phone' && account.recovery}
                                        <button
                                            use:tippy={{
                                                content: $_(
                                                    'Phone cannot be upgraded to a preferred provider',
                                                ),
                                                placement: 'top',
                                            }}
                                            class="btn-border h-4.5 w-4.5 relative flex cursor-not-allowed items-center justify-center overflow-hidden text-xs font-medium opacity-50"
                                        >
                                            ?
                                        </button>
                                    {:else if account.verifiedAt}
                                        <button
                                            use:tippy={{
                                                content: account.recovery
                                                    ? $_('Make Preferred')
                                                    : $_('Make Recovery'),
                                                placement: 'top',
                                            }}
                                            data-test="promote-{index}-btn"
                                            onclick={() =>
                                                account.recovery
                                                    ? makePreferred(account)
                                                    : makeRecovery(account)}
                                            class="btn-border h-4.5 w-4.5"
                                        >
                                            <PromoteIcon />
                                        </button>
                                    {/if}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {:else}
                {#if editMode}
                    <span
                        class="text-fallback text-sm mt-2 {$locale &&
                        $locale.startsWith('ar')
                            ? 'mr-6'
                            : 'ml-6'}">{$_('No providers')}</span
                    >
                {/if}
            {/each}
        {/if}
    </div>
</div>

{#if !editMode}
    <section
        class="mt-2 space-y-2 {$locale && $locale.startsWith('ar')
            ? 'mr-6'
            : 'ml-6'} p-0.5"
        transition:slide={{ duration: SLIDE_ANIM_MS }}
    >
        {#if recoveriesLength < 2}
            <button
                onclick={() => (dropdownStates.provider = true)}
                class="btn-danger dark:text-charcoal flex w-full items-center justify-start rounded-md bg-red-500 px-4 py-2 text-sm text-white"
            >
                <div class="w-7 flex-shrink-0">
                    <WarningIcon />
                </div>
                <div
                    class="flex flex-col {$locale && $locale.startsWith('ar')
                        ? 'text-right'
                        : 'text-left'}"
                >
                    <span class="font-bold uppercase"
                        >{$_('Wallet Recovery Not Set Up')}</span
                    >
                    {#if recoveriesLength === 1}
                        <span class="text-xs italic"
                            >{$_('Link one more provider to complete')}</span
                        >
                    {:else}
                        <span class="text-xs italic"
                            >{$_(
                                'Link two or more providers to complete',
                            )}</span
                        >
                    {/if}
                </div>
            </button>
        {/if}

        <div class="relative flex items-center">
            <Dropdown
                hasBackground={false}
                dataTest="add-provider-btn"
                ariaLabel={$_('Link another provider')}
                expanded={dropdownStates.provider}
                onclick={() => handleDropdown('provider')}
            >
                <div
                    class="flex h-12 items-center px-4 text-left"
                    aria-hidden="true"
                >
                    {$_('Link another provider')}
                </div>

                {#if dropdownStates.provider}
                    <div
                        class="z-30 flex w-full flex-col rounded-md p-4 text-left"
                        transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >
                        <LoginProviderGroup
                            prefix="Link"
                            ethereum={continueWithEthereumExtension}
                            managedEmailSuccess={verifyEmailSuccess}
                            showInstagram={true}
                            showManagedLogin={false}
                        />
                    </div>
                {/if}
            </Dropdown>
        </div>

        <Dropdown
            hasBackground={false}
            id="email-container"
            dataTest="add-email-btn"
            ariaLabel={global.data.profile.accounts.find(
                (i) => i.slug === 'email',
            )
                ? $_('Verify another email')
                : $_('Verify email')}
            expanded={dropdownStates.email}
            onclick={() => handleDropdown('email')}
        >
            <div
                class="flex h-12 w-full items-center justify-start gap-x-4 truncate px-4"
            >
                <MailIcon />
                <span class="text-left" aria-hidden="true">
                    {global.data.profile.accounts.find(
                        (i) => i.slug === 'email',
                    )
                        ? $_('Verify another email')
                        : $_('Verify email')}
                </span>
            </div>

            {#if dropdownStates.email}
                <div class="p-4" transition:slide={{ duration: SLIDE_ANIM_MS }}>
                    <VerifyEmail
                        otpState={() => (emailOTPState = true)}
                        success={verifyEmailSuccess}
                        error={verifyContactError}
                    />
                    {#if !emailOTPState && logins.filter((i) => i.claims.verified_email).length}
                        <span
                            class="my-4 block text-center text-lg uppercase opacity-60"
                        >
                            {$_('Or')}
                        </span>
                        <section
                            class="space-y-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            {#each logins.filter((i) => i.claims.verified_email) as provider}
                                <LoginProvider
                                    onclick={(e) =>
                                        continueWithProvider(
                                            provider,
                                            'email',
                                            e,
                                        )}
                                    {provider}
                                    prefix="Get email from"
                                />
                            {/each}
                        </section>
                    {/if}
                </div>
            {/if}
        </Dropdown>

        <Dropdown
            hasBackground={false}
            dataTest="add-phone-btn"
            ariaLabel={global.data.profile.accounts.find(
                (i) => i.slug === 'phone',
            )
                ? $_('Verify another phone')
                : $_('Verify phone')}
            expanded={dropdownStates.phone}
            onclick={() => handleDropdown('phone')}
        >
            <div
                class="flex h-12 w-full items-center justify-start gap-x-4 p-4"
            >
                <PhoneIcon />
                <span class="block text-left" aria-hidden="true"
                    >{global.data.profile.accounts.find(
                        (i) => i.slug === 'phone',
                    )
                        ? $_('Verify another phone')
                        : $_('Verify phone')}
                </span>
            </div>

            {#if dropdownStates.phone}
                <div class="p-4" transition:slide={{ duration: SLIDE_ANIM_MS }}>
                    <VerifyPhone
                        otpState={() => (phoneOTPState = true)}
                        success={verifyPhoneSuccess}
                        error={verifyContactError}
                    />

                    {#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
                        <section
                            class="mt-5 space-y-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            {#each logins.filter((i) => i.claims.verified_phone) as provider}
                                <div>
                                    <LoginProvider
                                        onclick={(e) =>
                                            continueWithProvider(
                                                provider,
                                                'phone',
                                                e,
                                            )}
                                        {provider}
                                        prefix="Get phone from"
                                    />
                                </div>
                            {/each}
                        </section>
                    {/if}
                </div>
            {/if}
        </Dropdown>
    </section>
{/if}
