<script>
    import { _ } from 'svelte-i18n'
    import { push as navigateTo } from 'svelte-spa-router'
    import { SLIDE_ANIM_MS } from '$src/constants'
    import { getProfile, deleteCookies, deleteLogin } from '$utils/api.js'
    import { global } from '$src/state.svelte.js'
    import { slide, fade } from 'svelte/transition'
    import CloseIcon from '$lib/icon/CloseIcon.svelte'
    import MenuIcon from '$lib/icon/MenuIcon.svelte'

    let {
        profile = false,
        editMode = false,
        overrideTitle = '',
        showMenu = true,
    } = $props()

    let menuOpen = $state(false)

    const showForgetMeBtn = $derived(
        global.data?.isManagedLoggedIn ||
            global.data?.preferred?.length ||
            global.data?.managed?.length,
    )

    const menuLinks = [
        {
            text: $_('Pictures'),
            id: '#pictures',
        },
        {
            text: $_('Providers'),
            id: '#personal-providers',
        },
        {
            text: $_('Applications'),
            id: '#applications',
        },
        // {
        //     text: $_('Devices'),
        //     id: '#devices',
        // },
    ]

    let logoutAjax = $state(false)
    async function logout() {
        logoutAjax = true
        global.spinner = true
        await deleteLogin({ clearSession: true })
        global.data = {}
        global.spinner = false
        logoutAjax = false
        navigateTo('/login')
    }

    let forgetMeAjax = $state(false)
    async function forgetMeHandler() {
        forgetMeAjax = true
        global.spinner = true
        await deleteCookies()
        global.data = await getProfile()
        global.spinner = false
        forgetMeAjax = false
        navigateTo('/login')
    }
</script>

<header
    data-test="hello-title-bar"
    class="bg-charcoal h-12 w-full flex-shrink-0 text-white dark:text-[#D4D4D4]"
    style="z-index: 60"
>
    <div
        class="container relative mx-auto flex h-full items-center justify-between px-3 {!global
            .data.isPersonalLoggedIn
            ? 'max-w-md'
            : 'max-w-2xl'}"
    >
        <div class="inline-flex w-32 items-center justify-start">
            {#if showMenu && global.data?.isPersonalLoggedIn && profile}
                <button
                    data-test="menu"
                    aria-label="Menu"
                    onclick={() => (menuOpen = !menuOpen)}
                    class="group inline-flex items-center"
                >
                    {#if !menuOpen}
                        <MenuIcon css="h-6 w-6" />
                    {:else}
                        <CloseIcon css="h-6 w-6" />
                    {/if}
                </button>
            {/if}
        </div>

        {#if menuOpen && global.data?.isPersonalLoggedIn && profile}
            <div class="container absolute left-0 top-12 mx-auto px-3">
                <div
                    data-test="menu-dropdown"
                    class="bg-charcoal fixed z-50 -ml-3 px-4 py-2 text-white md:-ml-0 dark:text-[#D4D4D4]"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul class="flex flex-col space-y-3">
                        {#each menuLinks as { text, id } (id)}
                            <li class="inline-block">
                                <button
                                    class="hover:underline focus:underline"
                                    onclick={() => {
                                        const main =
                                            document.querySelector('main')
                                        main.scrollTo({
                                            top:
                                                main.querySelector(id)
                                                    ?.offsetTop - 160,
                                            behavior: 'smooth',
                                        })
                                        menuOpen = false
                                    }}
                                >
                                    {text}
                                </button>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>
            <!-- svelte-ignore a11y_click_events_have_key_events -->
            <span
                aria-label="Close menu"
                tabindex="0"
                role="button"
                onclick={() => (menuOpen = false)}
                class="fixed bottom-0 left-0 top-12 h-screen w-screen bg-[#151515]/80"
                transition:fade={{ duration: SLIDE_ANIM_MS }}
            ></span>
        {/if}

        {#if overrideTitle}
            <span class="block w-64 truncate text-center text-xl font-semibold">
                {overrideTitle}
            </span>
        {:else if profile}
            <span class="block w-64 truncate text-center text-xl font-semibold">
                {#if editMode}
                    {$_('Edit Hellō Wallet')}
                {:else}
                    {$_('Hellō Wallet')}
                {/if}
            </span>
        {:else}
            <a
                href="https://www.hello.coop"
                target="_blank"
                class="text-xl font-semibold hover:underline focus:underline"
                >Hellō</a
            >
        {/if}

        <div class="inline-flex w-32 items-center justify-end">
            <span class="text-right">
                {#if !global.spinner}
                    {#if global.data?.isPersonalLoggedIn}
                        <button
                            data-test="logout-btn"
                            onclick={logout}
                            disabled={logoutAjax}
                            class="text-sm font-semibold hover:underline focus:underline"
                        >
                            {logoutAjax ? $_('Logging out') : $_('Log out')}
                        </button>
                    {:else if showForgetMeBtn}
                        <button
                            data-test="forget-me-btn"
                            onclick={forgetMeHandler}
                            disabled={forgetMeAjax}
                            class="text-sm font-semibold hover:underline focus:underline"
                        >
                            {forgetMeAjax ? $_('Forgetting') : $_('Forget me')}
                        </button>
                    {/if}
                {/if}
            </span>
        </div>
    </div>
</header>
